import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
    apiKey: "AIzaSyDhXMLJINJpIJr_qQaZh5BYtoMC17DvsdQ",
    authDomain: "dong-tracker.firebaseapp.com",
    databaseURL: "https://dong-tracker.firebaseio.com",
    projectId: "dong-tracker",
    storageBucket: "",
    messagingSenderId: "65297981431",
    appId: "1:65297981431:web:be542444cbfd7731"
});

export default firebase