import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import Teams from './Teams';
import { Header, Icon, Grid, Container, Divider, Segment, Button, Tab, Dimmer, Loader } from 'semantic-ui-react'
import MainChart from './MainChart';
import { isAfter, fromUnixTime, subDays, format, formatDistanceStrict} from 'date-fns';
import logo from './assets/hrderbylogo.png';
import Login from './Login';
import Profile from './Profile'
import firebase from './firebase';

const db = firebase.firestore();

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            activeTeam: -1,
            activePlayer: -1,
            recentDongCount: 10,
            user: null,
            stats: [],
            gameLogs: [],
            leagueLeaders: [],
            longestHomers: [],
            dataReady: false
        };
        this.setActiveTeam = this.setActiveTeam.bind(this);
        this.setActivePlayer = this.setActivePlayer.bind(this);
    }

    async componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    ...this.state,
                    user
                })
            } else {
                this.setState({
                    ...this.state,
                    user: null
                })
            }
        });



        let stats = []; // this is really an array of teams
        const teamsRef = db.collection('teams');
        const teamsSnapshot = await teamsRef.get();
        teamsSnapshot.forEach(team => stats.push({
            ...team.data(),
            id: team.id
        }));

        stats = await Promise.all(stats.map(async team => {
            team.players = await Promise.all(team.players.map(async playerDocRef => {
                const playerDoc = await playerDocRef.get();
                return playerDoc.data();
            }));
            return team;
        }));

        const gameLogs = [];
        const gameLogsRef = db.collection('gameLogs');
        const gameLogsSnapshot = await gameLogsRef.get();
        gameLogsSnapshot.forEach(gameLog => gameLogs.push(gameLog.data()));

        const leagueLeaders = [];
        const leagueLeaderSnapshot = await db.collection('leagueLeaders').get();
        leagueLeaderSnapshot.forEach(leaderDoc => leagueLeaders.push(leaderDoc.data()));

        const longestHomers = [];
        const longestHomerSnapshot = await db.collection('longestHomers').get();
        longestHomerSnapshot.forEach(homerDoc => longestHomers.push(homerDoc.data()));

        const transactionsSnapshot = await db.collection('substitutions').get();
        const transactions = await Promise.all(transactionsSnapshot.docs.map(async transDoc => this.getTransaction(transDoc, teamsRef)));

        this.setState({
            ...this.state,
            subHeader: this.getSubHeader(),
            stats,
            gameLogs,
            leagueLeaders,
            longestHomers,
            hotPlayers: await this.getHotPlayers(gameLogs),
            dataReady: true,
            transactions
        });

    }

    async getPlayerById(id) {
        const player = await db.collection('players').doc(id).get();
        return player.data();
    }

    async getTransaction(transDoc, teamsRef) {
        const transDocData = transDoc.data();
        const transTeamDoc = teamsRef.doc(transDocData.team.id);
        const teamSnapshot = await transTeamDoc.get();
    

        return {
            teamName: teamSnapshot.data().name,
            timestamp: transDocData.transactionDate.seconds,
            date: format(fromUnixTime(transDocData.transactionDate.seconds), 'LLL d h:mmaaaaa'),
            benchedPlayer: transDocData.benchedPlayer ? await this.getPlayerById(transDocData.benchedPlayer.id) : undefined,
            activatedPlayer: transDocData.activatedPlayer ? await this.getPlayerById(transDocData.activatedPlayer.id) : undefined
        };
        
    }

    setActiveTeam(activeTeam){
        this.setState({
            ...this.state,
            activeTeam: activeTeam,
            activePlayer: -1
        })
    }

    setActivePlayer(activePlayer){
        this.setState({
            ...this.state,
            activePlayer: activePlayer
        })
    }

    getPlayers() {
        const {stats} = this.state;
        const players = [];
        stats.forEach(team => {
            team.players.forEach(player => {
                players.push({
                    ...player,
                    team: team
                });
            })
        });
        return players;
    }

    getBestPlayers(max=5) {
        const players = this.getPlayers();
        players.sort(this.playerSort);
        return players.slice(0,max);
    }
    getWorstPlayers() {
        const players = this.getPlayers();
        players.sort(this.playerSort).reverse();
        return players.slice(0,5);
    }


    playerSort(playerA, playerB) {
        const playerAHomeRuns = playerA.stats ? playerA.stats.find(stat => stat.id === 'home_runs').value : 0;
        const playerBHomeRuns = playerB.stats ? playerB.stats.find(stat => stat.id === 'home_runs').value : 0;
        return playerBHomeRuns - playerAHomeRuns
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getSubHeader() {
        const tags = [
            'How many dongs will there be?',
            'These dongs will dictate the champion',
            'It is what you think it is',
            formatDistanceStrict(new Date(), new Date(2021,10,1), { unit: 'day' }) + ' more of dongs',
            // 'Will ' + stats[team1].name + '\'s dongs outnumber ' + stats[this.getRandomExcept(team1)].name + '\'s?'
        ];
        return tags[this.getRandomInt(0,3)];
    }

    getUnownedLeagueLeaders(){
        const { leagueLeaders } = this.state;
        const ownedPlayers = this.getPlayers().map(player => player.name);
        return leagueLeaders.filter(leader => ownedPlayers.indexOf(leader.name) < 0).sort((leaderA, leaderB) => {
            return leaderB.stats.find(stat => stat.id === 'home_runs').value - leaderA.stats.find(stat => stat.id === 'home_runs').value;
        })
    }

    getPlayerRatiosForGames(player, games=10) {

        const hr = player.logs.slice(0, games).reduce((accum, cur) => accum + cur.home_runs, 0);
        const gameCount = player.logs.slice(0, games).length;

        return {
                mostRecent: Math.max(...player.logs.map(log => new Date(log.date))),
                name: player.name,
                games: gameCount,
                hr: hr,
                ratio: (hr/gameCount),
                record: player.player
            };

    }

    findPlayer(numericalID) {
        const {stats} = this.state;
        let ownedPlayer = {};
        stats.forEach(team => {
            team.players.forEach(player => {
                if(Number(player.mlb_id) === Number(numericalID)) {
                    ownedPlayer = {
                        owner: team,
                        player: player.name
                    }
                }
            })
        });
        return ownedPlayer;
    }

    getLongestDongs(owned) {
        const {longestHomers} = this.state;

        return longestHomers
        .sort((dongA, dongB) =>
            dongB.distance - dongA.distance
        ).map(dong => {
            const foundPlayer = this.findPlayer(dong.batterId);

            return {
                ...dong,
                name: foundPlayer.player || dong.batterName,
                owner: foundPlayer.owner
            }
        })
        .filter(dong => dong.owner);
    }

    getTransactions() {
        return this.state.transactions.sort((transA, transB) => {
            return transB.timestamp - transA.timestamp;
        });
    }

    async getHotPlayers(gameLogs){
        let subStats = [];
        for(let gameCount = 10; gameCount > 0; --gameCount) {
            const subStat = gameLogs.map((player) => this.getPlayerRatiosForGames(player, gameCount))
            subStats = subStats.concat(subStat);
        }

        const avgGamesPlayed = gameLogs.reduce((agg, cur) => agg += (cur.logs ? cur.logs.length : 0), 0);

        const avgGamesPlayedPerPlayer = avgGamesPlayed / gameLogs.length;


        if(avgGamesPlayedPerPlayer >= 2) {
            // Remove homers in last 2 games.
            subStats = subStats.filter(stat => !(stat.ratio === 1 && stat.games <= 2));
        }

        const now = new Date();
        const threeDaysAgo = subDays(now, 3);

        // Remove any stats older than 3 games.
        subStats = subStats.filter(stat => isAfter(new Date(stat.mostRecent), threeDaysAgo));

        // Sort by best ratio.
        subStats = subStats.sort((playerA, playerB) => playerB.ratio - playerA.ratio);

        // Reduce down to only 1 stat per player
        subStats = subStats.filter((player, idx, arr) => {
            const currentRatio = player.ratio;
            const otherRatios = arr
                .filter(_player => _player.name === player.name)
                .map(_player => _player.ratio);
            return Math.max(...otherRatios, currentRatio) === currentRatio;
        });

        const finalStats = [];

        subStats.forEach(player => {
            if(!finalStats.filter(({name}) => name === player.name).length) {
                finalStats.push(player);
            }
        });

        return await Promise.all(finalStats.slice(0,5).map(async player => {
            const teamRef = await firebase.firestore().collection('teams').where('players', 'array-contains', player.record).get();
            player.team = teamRef.docs[0].data();
            return player;
        }));
    }

    getTopProjected(){
        return this.getPlayers().sort((playerA, playerB) => parseInt(playerB.projectedHomeruns) - parseInt(playerA.projectedHomeruns))
    }

    getMostRecent(count=10) {
        const {activeTeam, gameLogs, stats} = this.state;
        const currentTeam = stats[activeTeam];
        const logs = activeTeam >= 0 ? gameLogs
            .filter(player => currentTeam.players.map(p => p.mlb_id).indexOf(player.mlb_id) > -1)
            : gameLogs;
        const homeRunGames = logs.map(player => {
            return player.logs.filter(stats => stats.home_runs).map(log => ({
                ...log,
                name: player.name
            }))
        });
        const stiff = [].concat(...homeRunGames).sort((logA, logB) => {
            return new Date(logB.date) - new Date(logA.date);
        }).slice(0,count).map(log => ({
            ...log,
            highlights: log.highlights || []
        }));
        return stiff;
    }

    toggleRecentDongs(){
        if(this.state.recentDongCount > 10) {
            return this.setState({
                ...this.state,
                recentDongCount: 10
            })
        }

        // Otherwise increase the count
        this.setState({
            ...this.state,
            recentDongCount: 50
        })
    }


    profileUpdated(data) {
        const teams = this.state.stats;

        if(data.teams) {
            data.teams.forEach(team => {
                const idxToUpdate = teams.findIndex(_team => _team.id === team.ref.id);
                teams[idxToUpdate] = {
                    ...teams[idxToUpdate],
                    name: team.data.name
                }
            });

            this.setState({
                ...this.state,
                stats: teams
            });

        }
    }

    isTeamOwnedByCurrentUser(team) {
        if(!this.state.user) {
            return false;
        }
        return team.owner === this.state.user.uid;
    }

    clearActiveTeam() {
        this.setState({
            ...this.state,
            activeTeam: -1
        });
    }

    render() {

        const {stats, gameLogs, dataReady, user, hotPlayers, activeTeam} = this.state;
        const transactions = dataReady ? this.getTransactions() : [];

    return (
        <Container fluid className="App">
            <Container>
                <Grid stackable>
                <Grid.Row>
                    <Grid.Column className='title-col'>

                        <Header as='h3'>

                            <div className='header-text'>
                                <img src={logo} className='main-logo' alt='Dong Trackr 2019'/>
                                <Header.Subheader>{this.state.subHeader}</Header.Subheader>
                            </div>

                        </Header>

                        <div className="user-status-container">
                        {this.state.user ? <Profile onUpdate={data => this.profileUpdated(data)}/> : <Login/>}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5} className='list-col main-tabs-col'>
                    
                        {!dataReady && 
                                <Dimmer active>
                                    <Loader indeterminate>Loading Teams...</Loader>
                                </Dimmer>}

                        { dataReady && <Tab panes={[
                            {menuItem: 'Teams', render: () => <Tab.Pane className='chart-tab'>
                                    {/*<Divider horizontal>*/}
                                    {/*    <Header as='h4'>*/}
                                    {/*        <Icon name='group' />*/}
                                    {/*        Teams*/}
                                    {/*    </Header>*/}
                                    {/*</Divider>*/}
                                    <Teams teams={stats} gameLogs={gameLogs} setActiveTeam={this.setActiveTeam} setActivePlayer={this.setActivePlayer} user={user}/>
                                </Tab.Pane>},
                            {menuItem: 'Recent Dongs', render: () =>
                                    <Tab.Pane>
                                        { activeTeam >= 0 && <Segment.Group><Segment>
                                            <Header as='h4'>
                                                Filtered on: {stats[activeTeam].name} <span onClick={() => this.clearActiveTeam()}>(clear)</span>
                                            </Header>
                                            </Segment></Segment.Group>}
                                        <Segment.Group>
                                            {this.getMostRecent(this.state.recentDongCount).map((log, logIdx) =>
                                                <Segment key={logIdx}>
                                                    {log.name} ({log.home_runs})
                                                    {log.highlights.map(highlight => <a key={highlight.title} target='_blank' rel="noopener noreferrer" title={highlight.title} href={highlight.url}> <Icon name='video'/></a>)}
                                                    <span className='right floated'><small>{format(new Date(log.date + 'T10:00:00'), 'MMMM d')}</small></span>
                                                </Segment>
                                            )}
                                            <Segment className='text-center'><Button onClick={() => this.toggleRecentDongs()}>{this.state.recentDongCount > 10 ? 'Less' : 'More'}</Button></Segment>
                                        </Segment.Group>
                                    </Tab.Pane>},
                        ]}>

                        </Tab>}

                    </Grid.Column>
                    <Grid.Column width={11} className='chart-col'>
                        {!dataReady && 
                                <Dimmer active>
                                    <Loader indeterminate>Loading Data...</Loader>
                                </Dimmer>}

                        { dataReady && <MainChart data={stats} gameLogs={gameLogs} activeTeam={this.state.activeTeam} activePlayer={this.state.activePlayer}/>}
                    </Grid.Column>
                </Grid.Row>
                { dataReady && <Grid.Row>
                    <Grid.Column width={6} className='list-col'>

                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='chart line' />
                                Trending players
                            </Header>
                        </Divider>

                        <Segment.Group>
                            {hotPlayers && hotPlayers.map((player, idx) => (<Segment className={this.isTeamOwnedByCurrentUser(player.team) ? 'user-owned' : ''} key={idx}>
                                <Icon name='fire' className={`hot-${idx}`}/>
                                {player.name} <span>{player.hr} dongs</span> in last <span>{player.games} games</span>
                            </Segment>))}
                        </Segment.Group>

                    </Grid.Column>
                    <Grid.Column width={5} className='list-col'>

                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='sort amount up' />
                                Top Players
                            </Header>
                        </Divider>

                        { dataReady && <Segment.Group>
                            {this.getBestPlayers().map((player, idx) => (<Segment className={this.isTeamOwnedByCurrentUser(player.team) ? 'user-owned' : ''} key={idx}>{player.name} <span>{player.stats.find(stat => stat.id==='home_runs').value} hr</span> <small>({player.team.name})</small></Segment>))}
                        </Segment.Group> }

                    </Grid.Column>
                    <Grid.Column width={5} className='list-col'>

                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='frown' />
                                Draft shame list
                            </Header>
                        </Divider>

                        { dataReady && <Segment.Group>
                            {this.getUnownedLeagueLeaders().slice(0,5).map((player, idx) => (<Segment key={idx}>{player.name} <span>{player.stats.find(stat => stat.id==='home_runs').value} hr</span></Segment>))}
                        </Segment.Group>}

                    </Grid.Column>
                </Grid.Row>}
                {dataReady && <Grid.Row columns={3}>
                    <Grid.Column className='list-col'>

                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='trophy' />
                                Projected Top {(new Date()).getFullYear()} Dongs
                            </Header>
                        </Divider>

                        { dataReady && <Segment.Group>
                            {this.getTopProjected().slice(0,5).map((player, idx) => (<Segment className={this.isTeamOwnedByCurrentUser(player.team) ? 'user-owned' : ''} key={idx}>{player.name} <span>{player.projectedHomeruns} hr</span> ({player.team.name})</Segment>))}
                        </Segment.Group>}

                    </Grid.Column>
                    <Grid.Column className='list-col'>

                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='rocket' />
                                Longest Dongs
                            </Header>
                        </Divider>

                        { dataReady && <Segment.Group>
                            {this.getLongestDongs(true).slice(0,5).map((player, idx) => (<Segment  className={this.isTeamOwnedByCurrentUser(player.owner) ? 'user-owned' : ''} key={idx}>{player.name} <span>{player.distance} ft</span> ({player.owner.name})</Segment>))}
                        </Segment.Group>}

                    </Grid.Column>
                    <Grid.Column className='list-col'>

                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='exchange' />
                                Transactions
                            </Header>
                        </Divider>

                        { dataReady && <Segment.Group>
                            {transactions && transactions.length ? transactions.slice(0,5).map((transaction, idx) => (<Segment key={idx}>
                                {transaction.teamName} 
                                {transaction.benchedPlayer && <span className="plain"> benched <strong>{transaction.benchedPlayer.name}</strong></span>}
                                {transaction.activatedPlayer && <span className="plain"> for <strong>{transaction.activatedPlayer.name}</strong></span>}
                                <span> {transaction.date}</span>
                                </Segment>)) : <Segment className='text-center'>None yet.</Segment>}
                        </Segment.Group>}

                    </Grid.Column>
                </Grid.Row>}
                </Grid>
            </Container>
            <Container fluid className='page-footer'>
                <Container textAlign='right'>
                    &copy; {(new Date()).getFullYear()} <a target="_blank" rel="noopener noreferrer" href="http://forgemotion.com">Forge Motion</a>
                </Container>
                <Container textAlign='right' className='mt-1 mb-1 clearfix'>
                    <div id="forRecaptcha" className='right floated mb-2'/>
                </Container>
            </Container>
        </Container>
    );
  }
}

export default App;
