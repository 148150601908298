import React, {Component} from 'react'
import './Teams.scss';
import {Accordion, Icon, List, Container} from 'semantic-ui-react'

export default class AccordionExampleStyled extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTeamIndex: -1,
            activePlayer: -1,
            teams: this.props.teams,
            gameLogs: this.props.gameLogs,
        }

        this.handlePlayerClick = this.handlePlayerClick.bind(this);
    }

    appendPlayerChanges(teams) {
        return teams.map(team => {
            team.players = team.players.map(player => ({
                ...player,
                changes: this.getPlayerChanges(player)
            }));
            return team;
        })
    }

    componentDidMount() {}

    getTeams() {
        return this.appendPlayerChanges(this.state.teams).sort();
    }

    playerSort(playerA, playerB) {
        return playerB.stats.find(stat => stat.id === 'home_runs').value - playerA.stats.find(stat => stat.id === 'home_runs').value
    }


    isTeamOwnedByCurrentUser(team) {
        if(!this.props.user) {
            return false;
        }
        return team.owner === this.props.user.uid;
    }

    getPlayerChanges(player) {

        const change = {};

        const playerLogEntry = this.state.gameLogs.find(_player => _player.mlb_id === player.mlb_id)
        if (!playerLogEntry) {
            return change
        }

        if (playerLogEntry.logs[0]) {
            change.homersInLastGame = playerLogEntry.logs[0].home_runs;
        }

        if (!change.homersInLastGame) {
            let foundHomer = false;
            change.gamesSinceLastHomer = playerLogEntry.logs.reduce((agg, cur, idx) => {
                if (cur.home_runs && !foundHomer) {
                    foundHomer = true;
                    return idx;
                }
                return agg;
            }, 0);
        }

        return change;
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeTeamIndex} = this.state
        const newIndex = activeTeamIndex === index ? -1 : index

        this.props.setActiveTeam(newIndex);
        this.setState({activeTeamIndex: newIndex, activePlayer: -1})
    }

    handlePlayerClick = (event, player) => {
        event.preventDefault();
        this.props.setActivePlayer(player);
        this.setState({activePlayer: player})
    }

    getPlayer(player, idx) {

        const change = player.changes || {};

        const isActiveInLineup = player.active;
        const isSelectedPlayer = this.state.activePlayer.name === player.name;

        return (
            <List.Item key={idx} className={`${isSelectedPlayer ? 'selected-player' : ''} ${!isActiveInLineup ? 'player-inactive' : ''}`}>
                <div>
                    <button className='player-link' href='#'
                       onClick={(event) => this.handlePlayerClick(event, player)}>{player.name}</button>

                        
                    {change.homersInLastGame ? (<span className='homers-in-last-game'> (<Icon
                        name='caret up'/>{change.homersInLastGame})</span>) : ''}
                    {change.gamesSinceLastHomer ? (<span className='games-since-last'> (<Icon
                        name='wait'/>{change.gamesSinceLastHomer})</span>) : ''}

                    <span className="player-homers">
                        <span
                            className='slim-stats active-hrs'> {player.stats.find(stat => stat.id === 'home_runs').value || '0'} hr</span>
                        <span
                            className='slim-stats inactive-hrs'> {player.stats.find(stat => stat.id === 'active_home_runs').value || '0'} hr</span>
                    </span> 
                </div>
                <List bulleted as='ul' className='player-stats'>
                    <List.Item as='li'>Current
                        HR: <span>{player.stats.find(stat => stat.id === 'home_runs').value || '0'} hr</span></List.Item>
                    <List.Item as='li'>Projected HR: <span>{player.projectedHomeruns || 0} hr</span></List.Item>
                </List>
            </List.Item>
        );
    }

    team(team, curIndex, activeTeamIndex) {

        const isOwnedByUser = this.isTeamOwnedByCurrentUser(team);

        return (
            <div key={curIndex} className={isOwnedByUser ? 'user-owned' : ''}>
                <Accordion.Title active={activeTeamIndex === curIndex} index={curIndex} onClick={this.handleClick}>
                    <Icon name='dropdown'/>
                    {team.name} <small className="muted">({team.username})</small> <span>{this.getTotalHr(team)} hr</span>
                </Accordion.Title>
                <Accordion.Content active={activeTeamIndex === curIndex}>
                    <Container>Projected
                        Total: <span>{team.players.reduce((agg, curr) => agg + parseInt(curr.projectedHomeruns || 0), 0)} hr</span></Container>
                    <List>
                        <List.Item key='header'>
                        <span className="player-homers">
                            <span
                                className='slim-stats active-hrs'>overall</span>
                            <span
                                className='slim-stats inactive-hrs'>active</span>
                        </span> 
                        </List.Item>
                    </List>
                    <List ordered>
                        {team.players.sort(this.playerSort).map((player, idx) => this.getPlayer(player, idx))}
                    </List>
                </Accordion.Content>
                <Accordion.Content className='text-center icon-legend'
                                   active={activeTeamIndex === curIndex && this.state.activePlayer === -1}>
                    <span className='homers-in-last-game'><Icon name='caret up'/> - HRs in last game</span><br/>
                    <span className='games-since-last'><Icon name='wait'/> - Games since HR</span>
                </Accordion.Content>
            </div>
        )
    }

    getTotalHr(team) {
        return team
            .players
            .reduce((current, agg) => (agg.stats.find(stat => stat.id === 'active_home_runs').value || 0) + current, 0)
    }

    render() {
        const {activeTeamIndex} = this.state;
        const teams = this.getTeams();

        console.log(teams);

        return (
            <Accordion fluid styled>
                {teams.sort((teamA, teamB) => {
                    return this.getTotalHr(teamB) - this.getTotalHr(teamA);
                }).map((team, idx) => this.team(team, idx, activeTeamIndex))}
            </Accordion>
        )
    }
}