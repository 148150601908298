import React, {Component} from 'react';
import {Button, Message, Header, Icon, Input, Modal} from "semantic-ui-react";
import firebase from './firebase';

export default class Login extends Component {

    state={
        modalOpen: false,
        phone: '',
        code: '',
        needCodeVerification: false,
        recapVerified: false,
        loading: false
    };

    handleOpen = () => {
        this.setState({ modalOpen: true });
        // this.verifyRecaptcha();
    }
    handleClose = () => this.setState({ modalOpen: false })

    handlePhoneChange = (evt) => {

        this.setState({phone: evt.target.value});
    }

    handleCodeChange = (evt) => {
        this.setState({code: evt.target.value});
    }

    verifyRecaptcha = () => {
        if(this.state.recapVerified) {
            return;
        }

        this.appVerifier = new firebase.auth.RecaptchaVerifier('forRecaptcha', {
            size: 'invisible',
            render: 'explicit',
            badge: 'inline',
            callback: (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                console.log('recap solved');
                this.setState({
                    recapVerified: true
                })
            }
        });
    }

    handleLogin = () => {
        const phoneNumber = this.state.phone;
        const parsedPhoneNumber = '+1' + phoneNumber.replace(/\s-/gi, '');

        this.setState({
            ...this.state,
            error: null,
            loading: true,
        });

        firebase.auth().signInWithPhoneNumber(parsedPhoneNumber, this.appVerifier)
            .then(confirmationResult => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                this.confirmationResult = confirmationResult;
                this.setState({
                    ...this.state,
                    needCodeVerification: true,
                    loading: false
                })
            }).catch( error => {
            console.log('error', error)
            this.setState({...this.state, error: error.message, loading: false})
        });

        console.log('doing login');


    }

    handleCodeVerify = () => {
        this.setState({
            ...this.state,
            loading: true
        });
        this.confirmationResult.confirm(this.state.code)
            .then(result => {
                console.log(result.user)
                this.setState({
                    ...this.state,
                    loading: false
                });
                this.handleClose();
            })
            .catch(error => {
                console.log('error', error)
                this.setState({...this.state,
                    error: error.message,
                    loading: false,
                    needCodeVerification: false
                })
            })
    }

    render() {
        return (
            <Modal
                trigger={<Button inverted onClick={this.handleOpen} className='right floated refresh-button ml-2' color='green'>Login</Button>}
                basic
                size='mini'
                open={this.state.modalOpen}
                onClose={this.handleClose}
                onMount={this.verifyRecaptcha}
            >
                <Header icon='archive' content='Login' />
                <Modal.Content>
                    {!this.state.needCodeVerification && <div>
                        <p>
                            Use your mobile number to login:
                        </p>
                        <div>
                            <Input fluid label='+1' onChange={this.handlePhoneChange} placeholder='555-555-5555' />
                        </div>
                    </div>}
                    {this.state.needCodeVerification && <div>
                        <p>
                            Enter the code sent to your mobile device:
                        </p>
                        <div>
                            <Input fluid onChange={this.handleCodeChange} placeholder='123456' />
                        </div>
                    </div>}
                    {this.state.error && <Message negative>
                        <Message.Header>There was an error</Message.Header>
                        <p>{this.state.error}</p>
                    </Message>}
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={this.state.loading} color='green' onClick={!this.state.needCodeVerification ? this.handleLogin : this.handleCodeVerify } inverted>
                        <Icon loading={this.state.loading} name={this.state.loading ? 'circle notch' : 'checkmark'} />
                        {this.state.needCodeVerification ? 'Verify' : 'Login'}
                    </Button>
                    <Button basic color='red' onClick={this.handleClose} inverted>
                        <Icon name='remove' /> Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}