import React, { Component } from 'react';
import 'chart.js';
import {Bar} from 'react-chartjs-2';
import {format} from 'date-fns';

const graphColors = [
    '33,206,153',
    '255,255,255',
    '185,33,33',
    '255,255,0',
    '20,200,200',
];

export default class MainChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            teams: props.data
        }
    }

    getOptions(activeTeam, activePlayer){

        const teamHomers = this.props.data.map(team => {
            return [team.players.reduce((current, agg) => { return (agg.stats.find(stat => stat.id === 'active_home_runs').value || 0) + current }, 0)]
        }).map(hrs => hrs[0]);

        const showingTeam = activeTeam >= 0 && activePlayer < 0;
        return {
            title: {
                fontColor: '#EEE',
                display: true,
                text: showingTeam ? `${this.state.teams[activeTeam].name}'s Team` : 'Home Runs' + (activePlayer !== -1 ? ` for ${activePlayer.name}` : '' )
            },
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    id: 'y-axis-1',
                    scaleLabel: {
                        labelString: showingTeam ? 'HR/At bats per hr' : 'Home Runs',
                        display: true
                    },
                    ticks: {
                        beginAtZero: true,
                        min: (activeTeam >= 0 || activePlayer >= 0) ? 0 : Math.max(Math.min(...teamHomers) - 10, 0)
                    }
                },{
                    id: 'y-axis-2',
                    ticks: {
                        beginAtZero: true
                    },
                    display: showingTeam,
                    scaleLabel: {
                        labelString: 'AVG/OPS',
                        display: true
                    },
                    position: 'right'
                }]
            }
        }
    }

    componentWillUpdate(newProps){
        this.setState =  {
            ...this.state,
            data: this.getChartData(this.props.data, newProps.activeTeam, newProps.activePlayer)
        };
        return newProps;
    }

    getChartData(teams, activeTeam, activePlayer) {

        if(typeof activeTeam !== 'undefined' &&
            activeTeam !== -1 &&
            (typeof activePlayer === 'undefined' ||
            activePlayer === -1)
        ) {
            const selectedTeam = teams[activeTeam];
            const stats = selectedTeam.players[0].stats;
            const dataset = stats.map((stat, statIdx) => {
                return {
                    label: stat.name,
                    yAxisID: (['batting_average','ops'].indexOf(stat.id) >= 0) ? 'y-axis-2' : 'y-axis-1',
                    beginAtZero: true,
                    backgroundColor: 'rgba(' + graphColors[statIdx] + ',0.2)',
                    borderColor: 'rgba(' + graphColors[statIdx] + ',1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(' + graphColors[statIdx] + ',0.4)',
                    hoverBorderColor: 'rgba(' + graphColors[statIdx] + ',1)',
                    data: selectedTeam.players.map(player => {
                        return player.stats.find(playerStat => playerStat.id === stat.id).value
                    })
                }
            });

            return {
                labels: selectedTeam.players.map(({name}) => name),
                datasets: dataset
            }

        }


        if(typeof activePlayer !== 'undefined' && activePlayer !== -1) {

            const player = this.props.gameLogs
                .find(player => player.name === activePlayer.name);

            if(!player) {
                return this.getChartData(this.props.data, this.props.activeTeam);
            }

            const playerGameLogs = player.logs.sort((a,b) => {
                return new Date(a.date) - new Date(b.date);
            });
            const gameLogLabels = playerGameLogs
                .map(game => format(new Date(game.date), 'MMM d'));
            const baseLineHr = player.baseline.find(stat => stat.id === 'home_runs').value;
            const last10Hr = playerGameLogs
                .reduce((agg, cur) => (agg + cur.home_runs), 0);

            let curValue = baseLineHr - last10Hr;
            const gameLogStats = playerGameLogs
                .map(game => {
                    curValue = game.home_runs + curValue;
                    return curValue;
                });

            return {
                labels: gameLogLabels,
                datasets: [{
                    label: 'Home Runs',
                    type: 'line',
                    yAxisID: 'y-axis-1',
                    lineTension: 0,
                    beginAtZero: true,
                    backgroundColor: 'rgba(' + graphColors[0] + ',0.2)',
                    borderColor: 'rgba(' + graphColors[0] + ',1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(' + graphColors[0] + ',0.4)',
                    hoverBorderColor: 'rgba(' + graphColors[0] + ',1)',
                    data: gameLogStats
                }]
            }

        }

        const sortedTeams = teams.sort((teamA, teamB) => {
            return this.getTotalHr(teamB) - this.getTotalHr(teamA);
        });

        return {
            labels: sortedTeams.map(({name}) => name),
            datasets: [{
                label: 'Home Runs',
                beginAtZero: true,
                backgroundColor: 'rgba(33,206,153,0.2)',
                borderColor: 'rgba(33,206,153,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(33,206,153,0.4)',
                hoverBorderColor: 'rgba(33,206,153,1)',
                data: sortedTeams.map(team => {
                    return [team.players.reduce((current, agg) => { return (agg.stats.find(stat => stat.id === 'active_home_runs').value || 0) + current }, 0)]
                })
            }]
        }
    }

    getTotalHr(team) {
        return team
            .players
            .reduce((current, agg) => (agg.stats.find(stat => stat.id === 'active_home_runs').value || 0) + current, 0)
    }


    render() {
        const data = this.getChartData(this.props.data, this.props.activeTeam, this.props.activePlayer);
        const options = this.getOptions(this.props.activeTeam, this.props.activePlayer);

        return (
            <Bar data={data} options={options} />
        )
    }

}