import React, {Component} from 'react';
import {Button, Checkbox, Divider, Message, Header, Icon, Input, Modal, Segment} from "semantic-ui-react";
import firebase from './firebase';
import './Profile.scss'

export default class Profile extends Component {

    state={
        modalOpen: false,
        userInstance: {},
        userData: {
            teams: []
        },
        dataReady: false,
        loading: false
    };

    handleOpen = () => this.setState({ modalOpen: true });
    handleClose = () => this.setState({ modalOpen: false })

    updateProfile() {

    }

    async setInitialState() {

        const userInstance = firebase.auth().currentUser;
        const userInfo = await this.getUserProfile(userInstance);
        
        this.setState({
            ...this.state,
            userInstance,
            userData: {
                phoneNumber: userInstance.phoneNumber,
                teams: await Promise.all(userInfo.teams.map(async teamDocRef => {
                    const teamDoc = await teamDocRef.get();
                    const teamData = teamDoc.data();
                    teamData.players = await Promise.all(teamData.players.map(async playerRef => {
                        return await playerRef.get();
                    }))
                    return { 
                        data: teamData, 
                        ref: teamDocRef, 
                        subsRemaining: await this.getRemainingSubstitutions(teamDocRef) 
                    }
                })),
                displayName: userInstance.displayName,
                notifications: userInfo.notifications
            },
            dataReady: true
        });
    }

    getUserProfile = async (userInstance) => {

        const searchParams = new URLSearchParams(document.location.search);
        const hasOverride = searchParams.has('uidOverride')
        const uid = hasOverride ? searchParams.get('uidOverride') : userInstance.uid;

        // const userId = document.location.hash
        const userInfoRef = await firebase.firestore().collection("users").doc(uid)
            .get();

        if(userInfoRef.exists) {
            return userInfoRef.data();
        }

        return undefined;
    }

    handleInputChange = event => {
        const field = event.target;

        this.setState({
            ...this.state,
            userData: {
                ...this.state.userData,
                [field.name]: field.value
            }
        });
    }

    handleCheckChange = (event, data) => {

        const fieldName = data.name;
        this.setState({
            ...this.state,
            userData: {
                ...this.state.userData,
                [fieldName]: data.checked
            }
        });
    };

    handleDocChange = (refKey, ref, event) => {
        const newRefObjValue = this.state.userData[refKey].map(docObject => {
            if(docObject.ref === ref) {
                docObject.data[event.target.name] = event.target.value
            }
            return docObject;
        });
        this.setState({
            ...this.state,
            userData: {
                ...this.state.userData,
                [refKey]: newRefObjValue
            }
        });
    };

    hasRemainingTransactions(team) {
        return true;
    }

    getPlayerToActivate(team) {
        console.log(team);
        console.log(team.data);
        return team.data.players.find(playerSnapshot => !playerSnapshot.data().active);
    }

    async handleBenchPlayer(team, playerSnapshot) {
        if(!this.hasRemainingTransactions(team)) {
            return alert('No transactions remaining for team.')
        }

        const playerToActivate = this.getPlayerToActivate(team);
        console.log('playerToActivate', playerToActivate);
        console.log('playerToBench', playerSnapshot);
        console.log('uid', firebase.auth().currentUser.uid);
        console.log('team', team);

        if(!window.confirm(`Are you sure you want to bench ${playerSnapshot.data().name} and activate ${playerToActivate.data().name}? This will count toward your transaction limit`)) {
            return;
        }

        await firebase.firestore().collection("substitutions").add({
            benchedPlayer: playerSnapshot.ref,
            activatedPlayer: playerToActivate.ref,
            team: team.ref,
            transactionDate: firebase.firestore.FieldValue.serverTimestamp(),
            createdby_uid: firebase.auth().currentUser.uid,
            fake: true
        })

        await playerSnapshot.ref.update({
            active: false
        });

        await playerToActivate.ref.update({
            active: true
        });

        await this.setInitialState();

    }

    async getRemainingSubstitutions (teamRef) {
        const subsLimit = 4; // 3 allowed plus 1 initial benching
        const teamSubs = await firebase.firestore().collection("substitutions").where('team', '==', teamRef).get();
        return subsLimit - teamSubs.size;
    }

    handleActivatePlayer(playerSnapshot) {
        
    }

    handleUpdate = async () => {
        this.setState({
            ...this.state,
            loading: true,
            error: null
        });
        const {userInstance, userData} = this.state;

        try {

        // Update teams.
        userData.teams.map(async team => {
            await firebase.firestore().collection("teams").doc(team.ref.id).update({
                name: team.data.name
            }).catch(e => console.log(e));
        });

        // Update user record
        await firebase.firestore().collection("users").doc(userInstance.uid).set({
            notifications: !!userData.notifications
        }, {merge: true});


        // Update phone number
        // if(userInstance.phoneNumber !== userData.phoneNumber) {
        //     await userInstance.updatePhoneNumber(userData.phoneNumber);
        // }

        // Update the user
        await userInstance.updateProfile({
            displayName: userData.displayName
        });

            // Update successful.
            this.setState({
                ...this.state,
                loading: false
            });

        } catch(e) {
            this.setState({
                ...this.state,
                e,
                loading: false
            });
        }

        if(typeof this.props.onUpdate === 'function') {
            this.props.onUpdate(userData);
        }
        this.handleClose();

    }

    render() {

        const {userData, dataReady} = this.state;

        return (
            <Modal
                trigger={<Button circular color="green" inverted icon='user' className='right floated ml-3' onClick={this.handleOpen}/>}
                basic
                size='mini'
                open={this.state.modalOpen}
                onClose={this.handleClose}
                onMount={() => this.setInitialState()}
                className='profile'
            >
                <Header icon='user circle' content='Profile' />
                { dataReady && <Modal.Content>
                    <Divider horizontal>Your Info</Divider>
                        <p className='mt-2'>
                            Your Name:
                        </p>
                        <div>
                            <Input fluid onChange={this.handleInputChange} placeholder='My Team' defaultValue={userData.displayName || ''} name='displayName' />
                        </div>
                    <p className='mt-2'>
                        Phone Number:
                    </p>
                    <div>
                        <Input readOnly disabled fluid onChange={this.handleInputChange} placeholder='+12035555555' defaultValue={userData.phoneNumber} name='phoneNumber' />
                    </div>
                    {userData.teams.map((team, idx) => (<div key={idx}>
                        <Divider horizontal>Team Settings</Divider>
                            <p className='mt-2'>
                                Team Name:
                            </p>
                            <div>
                                <Input fluid onChange={evt => this.handleDocChange('teams', team.ref, evt)} placeholder='My Team' defaultValue={team.data.name || ''} name='name' />
                            </div>
                            <p className='mt-2'>
                                Substitutions Remaining: {team.subsRemaining || 0}
                            </p>
                            <p className='mt-2'>
                                Active Players:
                            </p>
                            <div>
                            <Segment.Group>
                                {team.data.players.filter(playerRef => playerRef.data().active).map((playerRef, idx) => <Segment key={idx} className="player-row">
                                    {playerRef.data().name}
                                    { team.subsRemaining ? <Button onClick={() => this.handleBenchPlayer(team, playerRef)} inverted size='mini' color='red'>Bench</Button> : ''}
                                </Segment>)}
                            </Segment.Group>
                            </div>
                            <p className='mt-2'>
                                Benched Players: {team.id}
                            </p>
                            <div>
                            <Segment.Group>
                                {team.data.players.filter(playerRef => !playerRef.data().active).map((playerRef, idx) => <Segment key={idx} className="player-row">
                                    {playerRef.data().name}
                                </Segment>)}
                            </Segment.Group>
                            </div>
                        </div>
                    ))}
                    <Divider horizontal>Notifications</Divider>
                        <p className='mt-2'>
                            Notifications:
                        </p>
                        <div>
                            <Checkbox color='green' onChange={this.handleCheckChange} checked={userData.notifications} name='notifications' toggle />
                        </div>
                    {this.state.error && <Message negative>
                        <Message.Header>There was an error</Message.Header>
                        <p>{this.state.error}</p>
                    </Message>}

                </Modal.Content> }
                <Modal.Actions>
                    <Button disabled={this.state.loading} color='green' onClick={this.handleUpdate} inverted>
                        <Icon loading={this.state.loading} name={this.state.loading ? 'circle notch' : 'checkmark'} />
                        Update
                    </Button>
                    <Button basic color='red' onClick={this.handleClose} inverted>
                        <Icon name='remove' /> Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}